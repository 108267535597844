import React, { Component, MouseEvent, ReactNode } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import ccLogo from "../assets/img/core-connect-logo.png";
import fdbLogo from "../assets/img/fdb-logo.png";
import { AuthService, JWTAuthService } from "../utils/auth";
import reportingRoutes from "../routes/ConfigRoutes";
import _ from "lodash";

/**
 * The main sidebar component, houses the sidebar menu.
 */
export default class Sidebar extends Component {

    // The authorisation service.
    authService: AuthService = new JWTAuthService();

    // A reference to the scroll bar component, we need to wait for it to mount.
    _scrollBarRef: PerfectScrollbar | null = null;

    /**
     * Toggles the footer menu.
     * @param e The mouse event.
     */
    toggleFooterMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        let parent = e.currentTarget.closest(".sidebar");
        parent?.classList.toggle("footer-menu-show");
    }

    /**
     * On render.
     * @returns A react node.
     */
    render(): ReactNode {
        return (
            <div className="sidebar shadow-sm">
                <div className="sidebar-header">
                    <Link to="/app" className="sidebar-logo" style={{marginLeft: "-10px"}}>
                        <img src={ccLogo} style={{width: "85%"}}></img>
                        <img src={fdbLogo} style={{width: "40%", marginTop: "4px", marginLeft: "2px"}}></img>
                    </Link>
                </div>
                <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
                    <SidebarMenu onUpdateSize={() => this._scrollBarRef?.updateScroll()} />
                </PerfectScrollbar>
                <div className="sidebar-footer">
                    <div className="sidebar-footer-top">
                        <div className="sidebar-footer-thumb">
                            <img src={userAvatar} alt="" />
                        </div>
                        <div className="sidebar-footer-body">
                            <h6><Link to="#">{this.authService.getUsername()}</Link></h6>
                            <p>User</p>
                        </div>
                        <Link onClick={this.toggleFooterMenu} to="" className="dropdown-link">
                            <i className="ri-arrow-down-s-line"></i>
                        </Link>
                    </div>
                    <div className="sidebar-footer-menu">
                        <nav className="nav">
                            <Link to="/signin" onClick={() => this.authService.signOut()}><i className="ri-logout-box-r-line"></i> Sign Out</Link>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

// Props for the sidebar menu.
type SidebarMenuProps = {
    onUpdateSize: Function
}

// A submenu item, used to dynamically create submenus.
type SubMenuDefinitionItem = {
    label: string,
    link: string
}

// A menu item, used to dynamically create menus.
type MenuDefinitionItem = {
    label: string,
    link?: string,
    icon: string,
    submenu?: Array<SubMenuDefinitionItem>
}

// A menu definition, contains a list of menus and submenus.
type MenuDefinition = Array<MenuDefinitionItem>

/**
 * Report menu items, use the route definition to populate these values, found 
 * in `routes/ReportingRoutes.tsx`.
 */
const reportMenu: MenuDefinition = reportingRoutes.map(reportRouteDef => {
    return {
        label: reportRouteDef.name,
        link: reportRouteDef.path,
        icon: reportRouteDef.icon
    };
});

/**
 * A sidebar menu component.
 */
class SidebarMenu extends Component<SidebarMenuProps, {}> {

    /**
     * Populates the menu based on the provided menu definition.
     * @param m The menu definition.
     */
    populateMenu = (m: MenuDefinition) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    )
                })
            }

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link || ""} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
                    ) : (
                        <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    /**
     * Toggles the menu group.
     * @param e The mouse event.
     */
    toggleMenu = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        let parent = e.currentTarget.closest('.nav-group');
        parent?.classList.toggle('show');

        this.props.onUpdateSize();
    }

    /**
     * Toggles the submenu while closing siblings' submenu
     * @param e The mouse event.
     */
    toggleSubMenu = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        let parent = e.currentTarget.closest('.nav-item');
        if (!parent) return;

        let node = parent?.parentNode?.firstChild as Element;
        
        while (node !== undefined && node !== null) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node?.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling as Element;
        }

        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    /**
     * On render.
     * @returns A react node.
     */
    render(): ReactNode {
        return (
            <React.Fragment>
                <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Config</div>
                    {this.populateMenu(reportMenu)}
                </div>
            </React.Fragment>
            
        )
    }
}

/**
 * Closes the sidebar footer menu when the user clicks elsewhere after opening
 * it.
 */
window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar: any = e.target;
    
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body")?.classList.remove("sidebar-show");
    }
});