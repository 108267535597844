import React, { Component, MouseEventHandler, ReactNode } from "react";
import ColouredBadge from "./ColouredBadge";
import { CAFMAssetDetails } from "../utils/assetMappingService";

/**
 * The props for the large CAFM asset details component.
 */
export type CAFMAssetLargeProps = {
    /** The CAFM asset details. */
    asset: CAFMAssetDetails,
    /** An optional react key prop. */
    key?: React.Key,
    /** On click mouse event handler. */    
    onClick?: MouseEventHandler<HTMLDivElement>
}

/**
 * A large component to display details for a CAFM asset.
 */
class CAFMAssetLarge extends Component<CAFMAssetLargeProps> {
    /** On render. */
    render(): ReactNode {
        const {onClick, key, asset} = this.props;
        return (
            <div
                key={key}
                className="card mb-3 shadow px-3 py-2 bg-white" style={{cursor: 'pointer'}}
                onClick={onClick}>
                <div className="d-flex justify-content-between">
                    <span className="fw-bold">{asset.assetDescription}</span>
                    <span className="font-monospace">{asset.locationNumber}</span>
                </div>
                <div className="mb-1 font-monospace">{asset.assetCode}</div>
                
                <div>{asset.manufacturer} - {asset.modelType}</div>
                <div>{asset.assetTypeId}</div>
                <div className="font-monospace">{asset.serialNumber}</div>
                <div>{asset.building}</div>
                <div>{asset.datePurchased}</div>
                
                <div className="d-flex justify-content-start my-2">
                    <span><ColouredBadge tag={asset.assetSystem} /></span>
                    <span><ColouredBadge tag={asset.assetGroup || ""} /></span>
                    <div><ColouredBadge tag={asset.assetTag} /></div>
                    <span><ColouredBadge tag={asset.assetType || ""} /></span>
                </div>
            </div>
        );
    }

}

export default CAFMAssetLarge;