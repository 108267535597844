import _ from "lodash";
import { Component, ReactNode } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { AssetMapping, CAFMAssetDetails, allAssetMappings, searchCafmAssets, updateAssetMappings } from "../utils/assetMappingService";
import { ToastContext } from "../components/Toasts";
import { debounce } from "../utils/utils";
import ColouredBadge from "../components/ColouredBadge";
import DModal from "../components/DModal";
import CAFMAssetSmall from "../components/CAFMAssetSmall";
import CAFMAssetLarge from "../components/CAFMAssetLarge";
import CopperTreeSystemSmall from "../components/CopperTreeSystemSmall";

/** The states the asset mapping query form can be in. */
enum AssetMappingQueryState {
    /** The form is closed. */
    CLOSED = 0,
    /** The form is open and idle. */
    OPEN,
    /** Fetching data on response. */
    FETCHING
}

// A BMS Asset ID.
type BMSAssetId = number;

// Asset mapping fields.
type AssetMappingFields = {
    /** The BMS asset ID. */
    bmsAssetId: BMSAssetId,
    /** Is the BMS asset ignored? */
    copperTreeSystemIgnored: boolean
}

/**
 * State for the asset mapping page component.
 */
type AssetMappingPageState = {
    // List of mappings from the server.
    serverMappings: Array<AssetMapping>,
    // Map/Object of copperTreeSystemId's to bmsAssetId's.
    localMappings: Record<string, AssetMappingFields>,
    // Display the find asset modal?
    displayFindAssetModal: boolean,
    // A map of asset ID's to CAFMAssetDetails we have recieved from the server.
    cachedCAFMAssetDetails: Record<BMSAssetId, CAFMAssetDetails>,
    // The asset query.
    assetQuery: string,
    // The asset query results.
    assetQueryResults: Array<CAFMAssetDetails>,
    // The system ID we are querying the asset ID for.
    assetQuerySystemId?: string,
    // The state the asset query form is in.
    assetMappingQueryState: AssetMappingQueryState
    // The abort controller for the current request.
    assetQueryRequestAbortController: Array<AbortController>,
    // Show ignored systems?
    showIgnored: boolean
}

/**
 * Asset Mapping page.
 */
class AssetMappingPage extends Component<RouteComponentProps, AssetMappingPageState> {

    /** Get the toast context. */
    static contextType = ToastContext
    context!: React.ContextType<typeof ToastContext>

    /** The state. */
    state: AssetMappingPageState = {
        serverMappings: [],
        localMappings: {},
        displayFindAssetModal: false,
        cachedCAFMAssetDetails: {},
        assetQuery: "",
        assetQueryResults: [],
        assetQuerySystemId: undefined,
        assetMappingQueryState: AssetMappingQueryState.CLOSED,
        assetQueryRequestAbortController: [],
        showIgnored: false
    }

    /**
     * Discards the local changes in the form.
     */
    discardChanges = () => {
        this.setState(state => { return {...state, localMappings: {}} });
    }

    /**
     * Uploads the changes to the server.`
     */
    saveChanges = () => {
        updateAssetMappings(
            _.map(this.state.localMappings, (mappingDetails, copperTreeSystemId) => {
                return {
                    copperTreeSystemId: copperTreeSystemId,
                    bmsAssetId: mappingDetails.bmsAssetId,
                    copperTreeSystemIgnored: mappingDetails.copperTreeSystemIgnored
                };
            }))
            .then(() => {
                if (!_.isEmpty(this.state.localMappings)) {
                    this.context.popToast(
                        "Changes saved",
                        "The changes have been saved.",
                        "success");
                }
            })
            .then(this.fetchServerMappings)
            .then(this.discardChanges);
    }

    /**
     * Maps an asset locally.
     * @param copperTreeSystemId The Copper Tree system ID to map.
     * @param bmsAssetId The asset ID.
     * @param copperTreeSystemIgnored Is the mapping ignored?
     */
    mapAssetLocally = (copperTreeSystemId: string, bmsAssetId: number, copperTreeSystemIgnored: boolean | null = null) => {
        this.setState(state => {
            const newState = {...state};
            const i = state.localMappings[copperTreeSystemId]?.copperTreeSystemIgnored;
            if (copperTreeSystemIgnored == null) {
                newState.localMappings[copperTreeSystemId] = {bmsAssetId, copperTreeSystemIgnored: i};
            } else {
                newState.localMappings[copperTreeSystemId] = {bmsAssetId, copperTreeSystemIgnored};
            }
            return newState;
        });
    }

    /**
     * Maps an asset locally.
     * @param copperTreeSystemId The Copper Tree system ID to map.
     * @param assetId The asset ID.
     */
    unmapAssetLocally = (copperTreeSystemId: string) => {
        this.setState(state => {
            const newState = {...state};
            newState.localMappings[copperTreeSystemId] = {
                bmsAssetId: 0,
                copperTreeSystemIgnored: this.state.serverMappings.find(v => v.copperTreeSystemId === copperTreeSystemId)?.copperTreeSystemIgnored || false
            }
            newState.displayFindAssetModal = false;
            return newState;
        });
    }
    

    /**
     * Returns a function which will update the local mapping with the provided
     * value.
     * @param copperTreeSystemId The copper tree system ID.
     * @returns A function which accepts an updated BMS asset ID.
     */
    updateLocalMappingFn = (copperTreeSystemId: string) => {
        return (el: React.ChangeEvent<HTMLInputElement>) => {
            this.mapAssetLocally(copperTreeSystemId, parseInt(el.target.value));
        }
    }

    /** Fetches the asset mappings on the server. */
    fetchServerMappings = () => {
        return allAssetMappings().then((res) => { 
            this.setState(state => {
                return {...state, serverMappings: res.allAssetMappings}
            });
        });
    }

    /** Adds an abort controller to the list. */
    pushAbortController = (ac: AbortController) => {
        this.setState(state => {
            return {
                ...state,
                assetQueryRequestAbortController: [...state.assetQueryRequestAbortController, ac]
            }
        })
    } 

    /**
     * Queries the backend for assets matching query.
     * @param query The asset query.
     */
    runAssetQuery = (query: string) => {
        // Cancel in flight request.
        if (
            this.state.assetQueryRequestAbortController.length != 0) {
            this.state.assetQueryRequestAbortController.forEach(ac => {ac.abort()});
        }

        // Run new request, setting the abort controller.
        this.setState(state => {
            const ac = new AbortController();

            searchCafmAssets(query, ac).then(res => {
                // Build new cache.
                const newCachedCAFMAssetDetails = this.state.cachedCAFMAssetDetails;
                res.matchingAssets.forEach(assetDetails => {
                    newCachedCAFMAssetDetails[assetDetails.id] = assetDetails;
                });
    
                this.setState(state => { 
                    return {
                        ...state,
                        assetQueryResults: res.matchingAssets,
                        cachedCAFMAssetDetails: newCachedCAFMAssetDetails,
                        assetQueryRequestAbortController: state.assetQueryRequestAbortController.filter(o => o === ac),
                        assetMappingQueryState: AssetMappingQueryState.OPEN
                    }
                });
            }).catch(e => {/* TODO handle cancel and rethrow on error. */})
            
            return {
                ...state,
                assetQueryAbortController: state.assetQueryRequestAbortController.push(ac),
                assetMappingQueryState: AssetMappingQueryState.FETCHING
            }
        })
        
        
    };

    /**
     * Gets the mapped asset details if they exist.
     * Will first check the local mappings and the cache, if it can't find it
     * then it grabs it from the server mapping. If it's in neither returns undefined.
     * @param assetMapping The asset mapping.
     * @returns The asset details if they exist somewhere, otherwise undefined.
     */
    getMappedAssetDetails = (assetMapping: AssetMapping): CAFMAssetDetails | undefined => {
        if (this.state.localMappings[assetMapping.copperTreeSystemId] &&
            this.state.localMappings[assetMapping.copperTreeSystemId].bmsAssetId == 0) {
            return undefined;
        }
        if (this.state.localMappings[assetMapping.copperTreeSystemId] != null 
            && this.state.cachedCAFMAssetDetails[this.state.localMappings[assetMapping.copperTreeSystemId].bmsAssetId] != null) {
            return this.state.cachedCAFMAssetDetails[this.state.localMappings[assetMapping.copperTreeSystemId].bmsAssetId];
        }

        if (assetMapping.bmsAssetDetails != null) {
            return assetMapping.bmsAssetDetails;
        }

        return undefined;
    }

    showModal = (copperTreeSystemId: string) => {
        this.setState(state => { return {...state, displayFindAssetModal: true, assetQuerySystemId: copperTreeSystemId} }) 
    }

    /**
     * On component mount.
     */
    componentDidMount() {
        this.fetchServerMappings();        
    }

    // Unregister navigation block callback.
    unblock: any = null;

    /** On component update. */
    componentDidUpdate() {
        const unsavedChanges = !(_.isEmpty(_.keys(this.state.localMappings)));

        if (unsavedChanges && this.unblock === null) {
            this.unblock = this.props.history.block("There are unsaved changes on the page, are you sure you want to navigate away?")
        } else if (!unsavedChanges && this.unblock !== null) {
            this.unblock();
            this.unblock = null;
        }
    }

    /** On component unmount. */
    componentWillUnmount() {
        // Clear form change block.
        if (this.unblock !== null) {
            this.unblock();
        }
    }

    /**
     * On render.
     * @returns A React node.
     */
    render(): ReactNode {
        const mappingItems = this.state.serverMappings.map(m => {
            const localBmsAssetId = this.state.localMappings[m.copperTreeSystemId]?.bmsAssetId;
            const localIgnored = 
                this.state.localMappings[m.copperTreeSystemId]  != null ?
                this.state.localMappings[m.copperTreeSystemId]?.copperTreeSystemIgnored
                : m.copperTreeSystemIgnored;

            if (!this.state.showIgnored && m.copperTreeSystemIgnored) {
                return <tr style={{display: 'none'}} key={m.copperTreeSystemId} />
            }
            
            const isLocalBmsAssetIdDifferentFromServer =
                (localBmsAssetId !== m.bmsAssetId && localBmsAssetId !== undefined)
                && !(m.bmsAssetId === undefined)

            // Create badges of the tags with random colours.
            const tagsJsx = m.copperTreeSystemTags.map((tag: String, index: number) => {
                return <ColouredBadge tag={tag} key={index} />;
            })

            // Get the asset details for the mapping.
            const assetDetails = this.getMappedAssetDetails(m);

            // Build the node for the asset details.
            const cafmAssetVal = (assetDetails != null)
                ? <CAFMAssetSmall
                    asset={assetDetails}
                    warning={isLocalBmsAssetIdDifferentFromServer}
                    isClickable={true}
                    onClick={() => { this.showModal(m.copperTreeSystemId) }}
                     />
                : <button
                        type="button"
                        className={"btn px-3 " + (isLocalBmsAssetIdDifferentFromServer ? "btn-outline-warning" : "btn-outline-primary") }
                        onClick={() => { this.showModal(m.copperTreeSystemId) }}>
                        <i className="ri-search-line"></i>
                  </button>

            return (
                <tr key={m.copperTreeSystemId}>
                    <td scope="row" className="align-middle">
                    <div className="d-flex justify-content-end">
                        <CopperTreeSystemSmall system={{id: m.copperTreeSystemId, name: m.copperTreeSystemName, tags: m.copperTreeSystemTags}} />
                        </div>
                    </td>
                    <td scope="row" className="align-middle"><i className="ri-arrow-left-right-line" /></td>
                    <td scope="row" style={{minWidth: "100px"}} className="align-middle">
                        <div className="d-flex justify-content-start">
                            {cafmAssetVal}
                        </div>
                    </td>
                    <td className="w-20">
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                            <div className="form-check form-switch">
                                <input
                                    id={m.copperTreeSystemId}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={localIgnored}
                                    onChange={(e) => {
                                        this.mapAssetLocally(m.copperTreeSystemId, m.bmsAssetId || 0, !localIgnored)
                                        }} />
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });

        // Create badges of the tags with random colours.
        const assetQueryResults = this.state.assetQueryResults.map(asset => {
            return (
                <CAFMAssetLarge
                  asset={asset}
                  key={asset.id}
                  onClick={(_) => { 
                    if (this.state.assetQuerySystemId != null) {
                        this.mapAssetLocally(this.state.assetQuerySystemId, asset.id);
                        this.setState(state => {
                            return {
                                ...state,
                                displayFindAssetModal: false,
                                assetQuery: "",
                                assetQueryResults: [],
                                assetQuerySystemId: undefined
                            }
                        })
                    }
                }}
                 />
            );
        });

        const assetQuerySystemDetails: AssetMapping | undefined = 
            this.state.serverMappings
                .filter(it => it.copperTreeSystemId === this.state.assetQuerySystemId)[0];
        const assetQuerySubText: string = (() => {
            switch (this.state.assetMappingQueryState) {
                case AssetMappingQueryState.CLOSED:
                    return "";
                case AssetMappingQueryState.OPEN:
                    return  this.state.assetQuery === "" ? "" 
                    : `Found ${this.state.assetQueryResults.length >= 100 ? "over" : ""} ${this.state.assetQueryResults.length} assets containing query`
                case AssetMappingQueryState.FETCHING:
                    return "Loading";
            }
        })();

        return (
            <div className="main px-4 py-3 px-lg-5 py-lg-4">
                <DModal
                    show={this.state.displayFindAssetModal} 
                    icon="ri-search-line"
                    title="Map Asset"
                    onClose={() => { 
                        this.setState(state => { 
                            return {
                                ...state,
                                displayFindAssetModal: false,
                                assetMappingQueryState: AssetMappingQueryState.CLOSED,
                                assetQueryResults: [],
                                assetQuery: ""
                            }
                        }) 
                    }}>
                    <div className="m-3">
                        <div className="mb-3 d-flex justify-content-center">
                            <CopperTreeSystemSmall system={{id: assetQuerySystemDetails?.copperTreeSystemId, name: assetQuerySystemDetails?.copperTreeSystemName, tags: assetQuerySystemDetails?.copperTreeSystemTags}} />
                        </div>
                        
                        <div className="mt-3">
                            <input 
                                autoFocus
                                type="text"
                                className={"form-control form-control-sm"}
                                value={this.state.assetQuery}
                                placeholder="Asset search query, matches to text in the asset details."
                                onChange={(e) => { 
                                        this.setState(state => { return {...state, assetQuery: e.target.value}});
                                        this.runAssetQuery(e.target.value);                                        
                                    }} />
                        </div>
                        <div className="mb-3 me-2 mt-2 d-flex justify-content-between fst-italic small text-secondary">
                            <button
                                type="button"
                                className="btn btn-outline-danger d-flex align-items-center px-2 py-1 small"
                                onClick={() => {
                                        if (assetQuerySystemDetails != null) { 
                                            this.unmapAssetLocally(assetQuerySystemDetails.copperTreeSystemId); 
                                        }
                                    }}>
                                    <i className="fs-6 ri-delete-bin-line me-2" />
                                    Clear Mapping
                            </button>
                            <span>
                                { assetQuerySubText }
                            </span>
                            
                        </div>
                        <div className="mb-3 d-flex justify-content-center">
                            
                        </div>
                        <div className="m-3">
                            { assetQueryResults }
                        </div>
                    </div>
                </DModal>
                <h2><i className="ri-arrow-left-right-line" style={{color: "#506fd9", paddingRight: "15px"}} />Asset Mapping</h2>
                <hr />
                <br />
                <div style={{position: "fixed", bottom: "0", right: "0", zIndex: "1000", flexDirection: "row"}} className="card px-3 py-3 shadow">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.saveChanges}>
                        Save Changes
                    </button>
                    <button 
                      type="button"
                      className="btn btn-outline-danger"
                      style={{marginLeft: "10px"}}
                      onClick={this.discardChanges}>
                        Discard Changes
                    </button>
                </div>
                <div className="card card-light card-bless mb-5">
                    <div className="card-header d-flex justify-content-between">
                        <div>Mapping Table</div>
                        <div className="d-flex">
                            <div className="me-3">Show ignored?</div>
                            <div className="form-check form-switch">
                                <input
                                    id="showIgnored"
                                    type="checkbox"
                                    className="form-check-input "
                                    checked={this.state.showIgnored}
                                    onChange={(e) => {
                                        this.setState(state => {
                                            return {
                                                ...state,
                                                showIgnored: !state.showIgnored
                                            }})
                                        }} />
                            </div>
                            
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="mb-0 table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Copper Tree System</th>
                                    <th></th>
                                    <th scope="col">CAFM Asset</th>
                                    <th>Ignore System?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mappingItems}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AssetMappingPage);
