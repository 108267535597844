import { webGet, webPost } from "./utils";

/** An audit log entry. */
export type AuditLogEntry = {
    /** The timestamp of this entry. */
    ts: String,
    /** The type of this entry. */
    type: Number,
    /** The entry message. */
    message: String
}

/** Response from the audit log endpoint. */
export type APIAuditLogResponse = {
    /** The list of audit log entries. */
    entries: Array<AuditLogEntry>
}

/**
 * Fetch all audit log entries from the backend.
 * @returns The response from the audit log endpoint.
 */
export function fullLog(): Promise<APIAuditLogResponse> {
    return webGet("audit/log")
}