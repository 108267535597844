import React, { Component, ReactNode } from "react";

export type ModalProps = {
    show: boolean,
    title: string,
    icon?: string,
    onClose: () => void,
    children: ReactNode
}

export type ModalHeaderProps = {
    children: ReactNode
}

export type ModalBodyProps = {
    children: ReactNode
}

export type ModalFooterProps = {
    children: ReactNode
}

class DModal extends Component<ModalProps> {

    static Body = function Body(props: ModalBodyProps) {
        return (
            <div className="modal-body">{props.children}</div>
        )
    }
    
    
    static Footer = function Footer(props: ModalFooterProps) {
        return (
            <div className="modal-footer">
                {props.children}
            </div>
        )
    }

    /** On render. */
    render(): ReactNode {
        if (!this.props.show) {
            return <></>;
        }
        
        return (
            <>
                <div className="fade modal-backdrop show"></div>
                <div role="dialog" aria-modal="true" className="fade modal show" style={{paddingRight: "17px", display: "block"}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="mx-2"><i className={"fs-4 " + this.props.icon} style={{color: "rgb(80, 111, 217)"}} /></div>
                                <div className="modal-title h4 mx-2">{this.props.title}</div>
                                <button type="button" className="btn-close mx-2" aria-label="Close" onClick={this.props.onClose}></button>
                            </div>
                            <div className="modal-body">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default DModal;