import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Toasts from './components/Toasts';

// Import CSS.
import "./assets/css/remixicon.css";

// Import SCSS.
import "./scss/style.scss";
import Main from './layouts/Main';
import SignIn from './pages/SignIn';

function App() {
  return (
    <React.Fragment>
      <Toasts>
        <BrowserRouter>
          <Switch>
            <Route path="/app">
              <Main />
            </Route>
            <Route path="/signin" exact={true}>
              <SignIn />
            </Route>
            <Route path="/" exact={true}>
              <Redirect to="/signin" />
            </Route>
          </Switch>
        </BrowserRouter>
      </Toasts>
    </React.Fragment>
  );
}

export default App;
