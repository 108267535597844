import { webGet, webPost } from "./utils";

/**
 * Copper Tree active rule details.
 */
export type CopperTreeRule = {
    /** The rule name. */
    name: string,
    /** The rule description. */
    description: string,
    /** The rule class. */
    ruleClass: string,
    /** Is the rule active in the Copper Tree system? */
    copperTreeActive: boolean,
    /** Is the rule active in generating work orders? */
    workOrderActive: boolean
}

/**
 * The all Copper Tree rules response object.
 */
export type APIAllCopperTreeActiveRulesResponse = {
    allCopperTreeActiveRules: Array<CopperTreeRule>
}

/**
 * Fetch all Copper Tree rules from the backend.
 * @returns The response from the copper tree endpoint.
 */
export function allCopperTreeActiveRules(): Promise<APIAllCopperTreeActiveRulesResponse> {
    return webGet("/config/copper-tree-active-rules");
}

/** Updates to Copper Tree work order generation status. */
export type CopperTreeRuleWOUpdate = {
    /** The rule name. */
    name: String,
    /** Is the rule active in generating work orders? */
    workOrderActive: Boolean
}

/** API Request for setting the work order generation for a Copper Tree rule. */
export type APICopperTreeGenerateWorkOrderRequest = {
    /** The Copper Tree rule name. */
    updates: Array<CopperTreeRuleWOUpdate>
}

/** API response for setting the work order generation for a Copper Tree rule. */
export type APICopperTreeGenerateWorkOrderResponse = {
    /** Did the rule update? */
    ok: boolean
}

/**
 * Sets the work order generation for a Copper Tree rule.
 * @param updates A list of Copper Tree rule work order generation status updates.
 */
export function updateRulesWorkOrderGeneration(updates: Array<CopperTreeRuleWOUpdate>): Promise<APICopperTreeGenerateWorkOrderResponse> {
    return webPost(
        "/config/copper-tree-active-rules/generate-work-orders",
        {updates: updates});
}
