import React, { Component, ReactNode } from "react";
import { CopperTreeSystemDetails } from "../utils/assetMappingService";
import ColouredBadge from "./ColouredBadge";
import SmallCard from "./SmallCard";

/**
 * The props for the CopperTreeSystemSmall component.
 */
export type CopperTreeSystemSmallProps = {
    /** The CAFM asset details. */
    system: CopperTreeSystemDetails
}

/**
 * A component to display limited CopperTreeSystemDetails.
 */
class CopperTreeSystemSmall extends Component<CopperTreeSystemSmallProps> {
    /** On render. */
    render(): ReactNode {
        const {id, name, tags} = this.props.system;
        const tagsJsx = tags.map((tag: String, index: number) => {
            return <ColouredBadge tag={tag} key={index} />;
        })

        return (
            <div className="border px-3 py-2 rounded">
                    <span className="me-3 fw-bold">{name}</span>
                    <span className="font-monospace me-3">{id}</span>    
                    <span className="">{tagsJsx}</span>
                    
            </div>
        );
    }

}

export default CopperTreeSystemSmall;