import React, { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter, Route, Switch, Redirect } from "react-router-dom";
import Sidebar from "./Sidebar";
import { AuthService, JWTAuthService } from "../utils/auth";
import HeaderMobile from "./HeaderMobile";
import CopperTreeActiveRulesPage from "../pages/CopperTreeActiveRules";
import reportingRoutes from "../routes/ConfigRoutes";
import headerBg from "../assets/img/header-bg.jpg";
import AssetMappingPage from "../pages/AssetMapping";
import AuditLogPage from "../pages/AuditLogPage";

/**
 * The main container for protected routes behind the sign in screen.
 */
class Main extends Component<RouteComponentProps> {

  // The authorisation service.
  authService: AuthService = new JWTAuthService();

  /**
   * On mount.
   */
  componentDidMount(): void {
    // If user is not signed in, redirect to signin.
    // TODO: Use react router interceptor to check for this.
    if (!this.authService.isSignedIn()) {
      this.props.history.push("/");
    }
    
    // TODO: Use react router interceptor to do this.
    // scroll to top when switching pages
    window.scrollTo(0, 0);    
  }
  
  /**
   * On render.
   * @returns A react node.
   */
  render(): ReactNode {
    return (
      <React.Fragment>
        <HeaderMobile />
        <Sidebar /> 
        <div
          className="main-image-header"
          style={{
            backgroundImage: `url(${headerBg})`,
            width: "100%",
            height: "110px",
            backgroundSize: "cover",
            backgroundPosition: "50% 45%",
            boxShadow: "inset 0px -10px 15px -3px rgba(0,0,0,0.5)"
          }} />
        <div style={{}}></div>
        <Switch>
          
          <Route
            path="/app/rules"
            component={CopperTreeActiveRulesPage}
            />
          <Route
            path="/app/mapping"
            component={AssetMappingPage} />
          <Route
            path="/app/log"
            component={AuditLogPage} />
          <Route path="/app">
            {/* Redirect to first report. */}
            <Redirect to="/app/mapping" />
            </Route>
        </Switch>
      </React.Fragment>
    )    
  }
}

export default withRouter(Main);