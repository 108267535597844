import React, { Component, MouseEventHandler, ReactNode } from "react";

/**
 * The props for the SmallCard component.
 */
export type SmallCardProps = {
    /** Clickable? */
    isClickable?: boolean
    /** The border colour. */
    borderCol?: string,
    /** The text colour. */
    textCol?: string,
    /** On click. */
    onClick?: MouseEventHandler<HTMLDivElement>,
    /** The children. */
    children: ReactNode
}

/**
 * A component to wrap the children in a card.
 */
class SmallCard extends Component<SmallCardProps> {
    /** On render. */
    render(): ReactNode {
        const classes = [ "card", "shadow-sm", "px-3", "py-2"];
        const {borderCol, textCol, isClickable, onClick} = this.props;

        if (isClickable != null && isClickable) {
            classes.push("bg-white");
            classes.push("text-primary")
            classes.push("border-primary")
        }

        if (borderCol != null) {
            classes.push("border-" + borderCol);
        }

        if (textCol != null) {
            classes.push("text-" + borderCol);
        }

        return (
            <div
                className={classes.join(" ")}
                onClick={onClick}
                style={{cursor: (isClickable != null && isClickable ? 'pointer' : ''), display: "inline-block"}}>
                {this.props.children}
            </div>
        );
    }

}

export default SmallCard;