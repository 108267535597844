import React, { Component, ReactNode } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import _ from "lodash";

/** Toast details. */
type ToastDetails = {
    /** The header text for the toast. */
    header: string,
    /** The body text for the toast. */
    body: string,
    /** The background for the toast. */
    bg: string,
    /** The toast ID. */
    id: string
}

/** Props for the Toasts component. */
export type ToastsProps = {
    children?: ReactNode
}

/** State for the Toasts component. */
type ToastsState = {
    /** The toasts. */
    toasts: Array<ToastDetails>
}

/** Toast context to allow children to add toasts. */
export const ToastContext = React.createContext({
    popToast: (header: string, body: string, bg: string) => {}
});
ToastContext.displayName = "ToastContext";

/** 
 * The Toasts component. 
 * Provides the popToast function to all sub components which pops up a toast.
 * 
 * To get access to the function in a sub component add the following properties
 * inside the class:
 * 
 * static contextType = ToastContext
 * context!: React.ContextType<typeof ToastContext>
 * 
 * Then call it like so:
 * this.context.popToast("Header", "body", "success");
 */
export default class Toasts extends Component<ToastsProps, ToastsState> {

    /** The number of seconds until a toast disappears. */
    static toastsTimeoutSeconds = 5 * 1000;

    /** The pop toasts function. */
    popToast = (header: string, body: string, bg: string) => {
        this.setState(({toasts}) => {
            const toastID = _.uniqueId();

            // Remove toast after n seconds.
            setTimeout(() => {
                this.removeToast(toastID);
            }, Toasts.toastsTimeoutSeconds);

            // Only have a maximum of 5 toasts.
            const clippedToasts = _.slice(toasts, 0, 5);

            return { toasts: [{header, body, bg, id: toastID}, ...clippedToasts] }
        });
    }

    /** Removes the toast with the specified ID. */
    removeToast = (toastID: string) => {
        this.setState(({toasts}) => {
            return { toasts: _.filter(toasts, (t) => t.id !== toastID) };
        });
        
    }

    /** The state. */
    state = {
        toasts: [],
    }

    /** On render. */
    render(): ReactNode {
        const toasts = this.state.toasts.map(({header, body, bg, id}) => {
            return (
                <Toast key={id} bg={bg} onClose={() => {this.removeToast(id)}} className={bg === "" ? "" : "text-white"}>
                    <Toast.Header>
                        <strong className="me-auto">{header}</strong>
                    </Toast.Header>
                    <Toast.Body>
                        {body}
                    </Toast.Body>
                </Toast>
            );
        });
        return (
            <React.Fragment>
                <ToastContext.Provider value={{popToast: this.popToast}}>
                    {this.props.children}
                </ToastContext.Provider>
                <ToastContainer style={{position: "fixed", top: "20px", right: "30px", zIndex: "1000"}}>
                    {_.reverse(toasts)}
                </ToastContainer>
            </React.Fragment>
        );
    }
}