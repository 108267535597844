// Utility functions.
import { JWTAuthService, AuthService } from "./auth";
import axios from "axios";

// Colours constants.
export enum Colours {
    DARK_BLUE_BLACK = "#1d1d2b",
    LIGHT_BLUE_BLACK = "#464652",
    DARK_RED = "#d95050",
    LIGHT_RED = "#ff8585",
    FREDON_RED = "#ed3324",
    DARK_BLUE = "#506fd9",
    LIGHT_BLUE = "#85b6ff"
}

export function dateToStr(date: Date) {
    const d = date;
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = '' + d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * Creates a new URL by combining the specified URLs.
 * @param {string} baseURL The base URL.
 * @param {string} relativeURL The relative URL.
 * @returns {string} The combined URL.
 */
export function combineURLs(baseURL: string, relativeURL: string) {
    return relativeURL
      ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
      : baseURL;
};

/**
 * Type for an API error.
 */
export type APIError = {
    code: string,
    message: string
}

/**
 * Post data to the backend.
 * @param payload The payload to send.
 * @param handler A promise handler, the callback is passed resolve and reject functions.
 * @param cancelAbortController An optional abort controller passed in by the
 *  user to cancel the request.
 * @returns A promise.
 */
export function webPost<T>(
    url: string,
    payload: unknown,
    cancelAbortController: AbortController | undefined = undefined) {
    const jwt = (new JWTAuthService() as AuthService).getToken();

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + jwt
        },
        signal: cancelAbortController?.signal
    }

    return new Promise<T>((resolve, reject) => {
        const appUrl: string = process.env.REACT_APP_URL || ""
        axios.post(combineURLs(appUrl, url), payload, config)
            .then((body) => resolve(body.data.body))
            .catch(reason => {
                if (!reason?.response?.data) {
                    reject({code: "UNKNOWN_ERROR",
                            message: "Something went wrong."});
                    return;
                }
                // User has invalid credentials, redirect to sign in page.
                if(reason.response.data.error.code == "INVALID_CREDENTIALS") {
                    (new JWTAuthService() as AuthService).signOut();
                    window.location.href = "/signin";
                } else {
                    reject(reason.response.data.error);
                }
            });
    });
}

/**
 * Get data from the backend.
 * @param handler A promise handler, the callback is passed resolve and reject functions.
 * @param params The query params.
 * @param cancelAbortController An optional abort controller passed in by the
 *  user to cancel the request.
 * @returns A promise.
 */
export function webGet(
    url: string,
    params: Object = {},
    cancelAbortController: AbortController | undefined = undefined) {
    const jwt = (new JWTAuthService() as AuthService).getToken();

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + jwt
        },
        params: {
            ...params
        },
        signal: cancelAbortController?.signal
    }

    return new Promise<any>((resolve, reject) => {
        const appUrl: string = process.env.REACT_APP_URL || "";
        axios.get(combineURLs(appUrl, url), config)
            .then((body) => resolve(body.data.body))
            .catch(reason => {
                if (!reason?.response?.data) {
                    reject({code: "UNKNOWN_ERROR",
                            message: "Something went wrong."});
                    return;
                }
                // User has invalid credentials, redirect to sign in page.
                if(reason.response.data.error.code == "INVALID_CREDENTIALS") {
                    (new JWTAuthService() as AuthService).signOut();
                    window.location.href = "/signin";
                } else {
                    reject(reason.response.data.error);
                }
            });
    });
}

/**
 * Determines whether a value is an object.
 * @param value The value.
 * @returns True if the value is an object, otherwise false.
 */
export function isObject(value: any): boolean {
    return (typeof value === 'object' &&
        value !== null &&
        value !== undefined);
}

/**
 * Determines whether a value is an object.
 * @param value The value.
 * @returns True if the value is a value, otherwise false.
 */
export function isAtom(value: any): boolean {
    return (!isObject(value));
}

/**
 * Walks a javascript object calling the cb function on each element.
 * It operates like so:
 * When it encounters an object, it will call the cb function.
 *   If cb returns null, it continues on and begins walking the fields.
 *   If cb returns an object, then it replaces the current object with the
 *     returned value.
 * 
 *   If the cb wasn't called it will walk each field and call cb on the value.
 *     If cb returns null, it will leave the field in place and continue.
 *     If cb returns an object, it will replace the field value with that object.
 * 
 * @param reportDefinition 
 * @param cb 
 */
export function walkObj(obj: any, cb: (a: any) => any) {
    // Get object fields like so: Object.keys(obj)
    // Determine if obj is an array like so: Array.isArray(obj)
    // Determine if obj is an obj like so: isObject(obj)

    // If the object is an atom, just call cb and return the result.
    if (isAtom(obj)) {
        const cbRes = cb(obj);
        if (cbRes === null) {
            return obj;
        } else {
            return cbRes;
        }
    }

    // If the object is an array, call cb on it and if there's a result, return
    // it, otherwise walk the array.
    if (Array.isArray(obj)) {
        const arrRes = cb(obj);
        if(arrRes !== null) {
            return arrRes;
        }

        const keys = Object.keys(obj)
        const newArr: Array<any> = [];
        for (const k in keys) {
            const cbRes = cb(obj[k]);
            if (cbRes === null) {
                newArr[k] = walkObj(obj[k], cb);
            } else {
                newArr[k] = cbRes;
            }
        }

        return newArr;
    }

    // If the object is an object, call cb on it and if there's a result, return
    // it, otherwise walk the object.
    if (isObject(obj)) {
        const objRes = cb(obj);
        if(objRes !== null) {
            return objRes;
        }

        const newObj: any = {};
        for (const k in obj) {
            if (obj.hasOwnProperty(k)) {
                const cbRes = cb(obj[k]);
                if (cbRes === null) {
                    newObj[k] = walkObj(obj[k], cb);
                } else {
                    newObj[k] = cbRes;
                }
            }
        }

        return newObj;
    }

    throw Error(`Unknown value encountered: ${JSON.stringify(obj, null, 2)}`);

}

/**
 * Gets a hashcode for a string.
 * @param s The string.
 * @returns The hash code.
 */
export function hashCode(s: String): number {
    var hash = 0;
    for (var i = 0; i < s.length; i++) {
        var code = s.charCodeAt(i);
        hash = ((hash<<5)-hash)+code;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

/** A colour palette. */
export const COLOUR_PALETTE = [
    "#d92120",
    "#e03d25",
    "#e55b2b",
    "#e77730",
    "#e68e34",
    "#e1a038",
    "#d9ae3c",
    "#cdb741",
    "#bebc47",
    "#aebe4f",
    "#9dbe59",
    "#8cbc66",
    "#7bb876",
    "#6db289",
    "#60ab9e",
    "#55a0b2",
    "#4c92c0",
    "#4581c1",
    "#416cb7",
    "#3f55a6",
    "#413c94",
    "#472786",
    "#56197f",
    "#781c81"
  ];

/**
 * Debounces a function.
 * @param func The function to debounce.
 * @param timeout The timeout.
 * @returns A function with a timeout wrapped around it.
 */
export function debounce(func: any, timeout = 500){
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer);
        /** @ts-ignore */
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}