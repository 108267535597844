import axios from "axios";
import Cookie from "js-cookie";

/**
 * An error if the sign in failed.
 */
export enum SignInError {
    INVALID_USER = "INVALID_USER",
    INVALID_PASSWORD = "INVALID_PASSWORD"
}

/**
 * When there is an error with sign in, an error code and a message will be
 * provided.
 */
export type SignInErrorResponse = {
    code: SignInError,
    message: string,
}

/**
 * After sign in then we will get either an OK response as a true value or an
 * error.
 */
export type SignInResponse = true | SignInErrorResponse;

/**
 * An interface for services providing authorisation facilities.
 */
export interface AuthService {
    /**
     * Signs a user in.
     * @param username The user's username.
     * @param password The user's password.
     * @returns A promise resolving to either a response token or an error object.
     */
    signIn(username: string, password: string): Promise<SignInResponse>,

    /**
     * Signs the user out.
     */
    signOut(): void,

    /**
     * Is the user signed in?
     * @returns True if the user is signed in, otherwise false.
     */
    isSignedIn(): boolean,

    /**
     * Gets the user's username.
     * @returns The username if it exists in the JWT payload, otherwise
     * undefined.
     */
    getUsername(): string | undefined,

    /**
     * Gets the API token.
     * @returns The API token if it is set, otherwise undefined.
     */
    getToken(): string | undefined
}

/**
 * An implementation of the AuthService interface backed by JWT in cookies.
 */
export class JWTAuthService implements AuthService {

    /**
     * Sets the JWT cookie.
     * @param jwt The JWT string.
     */
    setJwt(jwt: string) {
        Cookie.set("jwt", jwt);
    }

    /**
     * Gets the JWT cookie.
     * @returns The JWT cookie if it is set, otherwise undefined.
     */
    getJwt(): string | undefined {
        return Cookie.get("jwt");
    }

    /**
     * Clears the JWT.
     */
    clearJwt() {
        Cookie.remove("jwt");
    }

    /**
     * Gets the JWT payload.
     * @returns The JWT payload if it exists, otherwise undefined.
     */
    getJwtPayload(): any | undefined {
        const jwt: string | undefined = this.getJwt();
        if (jwt == undefined) {
            return undefined;
        }
    
        const b64EncodedPayload: string | undefined = jwt.split(".")[1];
        if (b64EncodedPayload == undefined) {
            throw new Error(`Invalid JWT ${jwt}`);
        }
    
        return JSON.parse(atob(b64EncodedPayload));
    }

    /**
     * Gets the API token.
     * @returns The API token if it is set, otherwise undefined.
     */
    getToken(): string | undefined {
        return this.getJwt();
    }

    /**
     * Gets the user's username.
     * @returns The username if it exists in the JWT payload, otherwise
     * undefined.
     */
    getUsername(): string | undefined {
        const payload: any | undefined = this.getJwtPayload();
        if (payload == undefined) return undefined;
    
        const username: string | undefined = payload.username;
        if (username == undefined) {
            throw new Error(`Could not find username in payload ${payload}`);
        }
    
        return username;
    }

    /**
     * Signs the user in.
     * @param username The user's username.
     * @param password The user's password.
     */
    signIn(username: string, password: string): Promise<SignInResponse> {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_URL
            axios.post(url + '/login', {username, password})
                .then(body => {
                    this.setJwt(body.data.body.token);
                    resolve(true);
                })
                .catch(reason => reject(reason.response.data.error));
        });
    }

    /**
     * Signs the user out.
     */
    signOut(): void {
        this.clearJwt();
    }

    /**
     * Is the user signed in?
     * @returns True if the user is signed in, otherwise false.
     */
    isSignedIn(): boolean {
        return this.getUsername() !== undefined;
    }
}
