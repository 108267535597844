/**
 * Report routes.
 */

import { ConfigRouteDefinition } from "./routeUtils";

/**
 * A list of report routes.
 */
const reportingRoutes: Array<ConfigRouteDefinition> = [
    {
        path: "/app/mapping",
        name: "Asset Mapping",
        icon: "ri-arrow-left-right-line"
    },
    {
        path: "/app/rules",
        name: "Copper Tree Active Rules",
        icon: "ri-edit-line"
    },
    {
        path: "/app/log",
        name: "Info Log",
        icon: "ri-file-list-3-line"
    }   
]

export default reportingRoutes;