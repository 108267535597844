import { Component, ReactNode } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { CopperTreeRule, CopperTreeRuleWOUpdate, allCopperTreeActiveRules, updateRulesWorkOrderGeneration } from "../utils/copperTreeService";
import _ from "lodash";
import { Toast, ToastContainer } from "react-bootstrap";
import { ToastContext } from "../components/Toasts";

/**
 * The page state.
 */
type CopperTreeActiveRulesPageState = {
    /** The rules on the server. */
    serverRules: Array<CopperTreeRule>,
    /** The local copy of the rules. */
    localRules: Array<CopperTreeRule>
}

/**
 * Copper Tree active rules page.
 */
class CopperTreeActiveRulesPage extends Component<RouteComponentProps, CopperTreeActiveRulesPageState> {

    /** Get the toast context. */
    static contextType = ToastContext
    context!: React.ContextType<typeof ToastContext>

    /** State. */
    state: CopperTreeActiveRulesPageState = {
        serverRules: [],
        localRules: []
    }

    /**
     * Compares the server version to the local version and returns the differences.
     * @returns The differences.
     */
    getLocalDifferences(): Array<CopperTreeRule> {
        const local = _.orderBy(this.state.localRules, ["name"], ["asc"]);
        const server = _.orderBy(this.state.serverRules, ["name"], ["asc"]);
        return _.differenceWith(local, server, _.isEqual);
    }

    /**
     * Is there any unsaved changes?
     * @returns true if there are unsaved changes otherwise false.
     */
    isUnsavedChanges(): boolean {
        return !(_.isEmpty(this.getLocalDifferences()));
    }

    /**
     * Discards the local changes in the form.
     */
    discardChanges = () => {
        this.setState(state => { return {...state, localRules: state.serverRules} });
    }

    /**
     * Persists local changes to the server.
     */
    saveChanges = () => {
        // Note the argument order is important here, the state of the local rules
        // will be returned.
        const diffs = this.getLocalDifferences();
        const updates: Array<CopperTreeRuleWOUpdate> =
            diffs.map(rule => {
                return {name: rule.name, workOrderActive: rule.workOrderActive};
            });
        
        updateRulesWorkOrderGeneration(updates)
            .then(res => {
                this.fetchCopperTreeRules();
                if (!_.isEmpty(diffs)) {
                    this.context.popToast(
                        "Changes saved",
                        "The changes have been saved.",
                        "success");
                }
            })
            .catch(err => console.log(err));
    }

    /** Fetches the Copper Tree rules from the server. */
    fetchCopperTreeRules = () => {
        allCopperTreeActiveRules().then(res => {
            this.setState(state => {
                return {
                    ...state,
                    serverRules: res.allCopperTreeActiveRules,
                    localRules: res.allCopperTreeActiveRules
                };
            });
        });
    }

    /**
     * Returns a function which will toggle the local rules work order 
     * generation with the provided value.
     * @param name The copper tree rule name.
     * @returns A function which accepts a copper tree rule name and updates the
     * work order generation status.
     */
    toggleRuleWorkOrderGenerationStatusFn = (name: string) => {
        return (el: React.ChangeEvent<HTMLInputElement>) => {
            this.setState(state => {
                return {
                    ...state,
                    localRules: state.localRules.map(rule => {
                        return rule.name === name ? {...rule, workOrderActive: !rule.workOrderActive} : rule;
                    })
                };
            });
        }
    }

    // Unregister navigation block callback.
    unblock: any = null;

    /** On component mount. */
    componentDidMount(): void {
        this.fetchCopperTreeRules();
    }

    /** On component update. */
    componentDidUpdate(prevProps: RouteComponentProps, prevState: CopperTreeActiveRulesPageState) {
        if (this.isUnsavedChanges() && this.unblock === null) {
            this.unblock = this.props.history.block("There are unsaved changes on the page, are you sure you want to navigate away?")
        } else if (!this.isUnsavedChanges() && this.unblock !== null) {
            this.unblock();
            this.unblock = null;
        }
    }

    /** On component unmount. */
    componentWillUnmount() {
        // Clear form change block.
        if (this.unblock !== null) {
            this.unblock();
        }
    }

    /**
     * On render.
     * @returns A React node.
     */
    render(): ReactNode {
        const listItems = this.state.localRules.map((rule: CopperTreeRule, idx: number) => {
            // Does the server work order toggle state match the local toggle state?
            const isServerAndLocalTheSame = this.state.serverRules[idx].workOrderActive === rule.workOrderActive;

            // Is the rule disabled in Copper Tree?
            const isDisabledInCopperTree = !rule.copperTreeActive;

            // Calculates the rule text colour.
            const ruleTextColour = (() => {
                if (isDisabledInCopperTree) {
                    return "rgba(var(--bs-secondary-rgb))";
                } else if (isServerAndLocalTheSame) {
                    return "";
                } else {
                    return "rgba(var(--bs-warning-rgb))";
                }
            })();

            return (
                <div
                  className="list-group-item"
                  key={rule.name}>
                    <div className="row">
                        <div className="col">
                            <div className="form-check form-switch">
                                <input
                                  id={rule.name}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={rule.workOrderActive}
                                  disabled={isDisabledInCopperTree}
                                  onChange={this.toggleRuleWorkOrderGenerationStatusFn(rule.name)} />
                                <span style={{fontWeight: "bold", color: ruleTextColour}}>
                                    {rule.name}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span style={{color: ruleTextColour}}>{rule.description}</span>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className="main px-4 py-3 px-lg-5 py-lg-4">
                <h2><i className="ri-edit-line" style={{color: "#506fd9", paddingRight: "15px"}} />Copper Tree Active Rules</h2>
                <hr />
                <br />
                <div style={{position: "fixed", bottom: "0", right: "0", zIndex: "1000", flexDirection: "row"}} className="card px-3 py-3 shadow">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.saveChanges}>
                        Save Changes
                    </button>
                    <button 
                      type="button"
                      className="btn btn-outline-danger"
                      style={{marginLeft: "10px"}}
                      onClick={this.discardChanges}>
                        Discard Changes
                    </button>
                </div>
                <div className="card card-light card-bless mb-5">
                    <div className="card-header">Should the rule generate work orders?</div>
                    <div className="card-body">
                        <div className="list-group">
                            {listItems}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CopperTreeActiveRulesPage);