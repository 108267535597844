import React, { ChangeEvent, createRef, ReactNode } from "react";
import { FormControlProps } from "react-bootstrap";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import ccLogo from "../assets/img/core-connect-logo.png";
import fdbLogo from "../assets/img/fdb-logo.png";
import bg1 from "../assets/img/signin-bg.jpg";
import {
    JWTAuthService, 
    AuthService,
    SignInResponse,
    SignInErrorResponse,
    SignInError
} from "../utils/auth";

// The props type.
type Props = {};

// The state type.
type State = {
    username: string,
    password: string,
    usernameIsInValid: boolean,
    passwordIsInValid: boolean,
    usernameErrorMessage: string,
    passwordErrorMessage: string,
};

/**
 * A sign in page react component.
 */
class SignIn extends React.Component<RouteComponentProps<Props>, State> {

    // The auth service.
    authService: AuthService = new JWTAuthService();

    // The component state.
    state: State = {
        username: "",
        usernameIsInValid: false,
        usernameErrorMessage: "",
        password: "",
        passwordIsInValid: false,
        passwordErrorMessage: ""
    }

    // The username input field reference.
    usernameInputRef: React.RefObject<HTMLInputElement> = createRef();

    // The password input field reference.
    passwordInputRef: React.RefObject<HTMLInputElement> = createRef();

    /**
     * On component mount.
     */
    componentDidMount(): void {
        // Redirect user to the app if they are already signed in.
        if (this.authService.isSignedIn()) {
            this.props.history.push("/app");
        }
    }

    /**
     * Signs the user in.
     */
    signIn(): void {
        // Reset UI state.
        this.setState({
            ...this.state,
            usernameIsInValid: false,
            usernameErrorMessage: "",
            passwordIsInValid: false,
            passwordErrorMessage: ""
        });
        
        // Ensure the user isn't currently logged in, this should be redundant.
        this.authService.signOut();

        // Sign the user in.
        this.authService.signIn(this.state.username, this.state.password)
            // If successful, then redirect to the app.
            .then(() => {
                this.props.history.push("/app")
            })
            // If there is an error, update the UI.
            .catch((err: SignInResponse) => {
                err = err as SignInErrorResponse;
                if (err.code == SignInError.INVALID_USER) {
                    this.setState({
                        ...this.state,
                        usernameIsInValid: true,
                        usernameErrorMessage: err.message
                    });
                    this.usernameInputRef.current?.focus();
                } else if (err.code == SignInError.INVALID_PASSWORD) {
                    this.setState({
                        ...this.state,
                        passwordIsInValid: true,
                        passwordErrorMessage: err.message
                    });
                    this.passwordInputRef.current?.focus();
                    this.passwordInputRef.current?.select();
                } else {
                    throw Error(`Unexpected sign in error ${err}`);
                }
            });
    }

    /**
     * On username input field change.
     * @param e The change event.
     */
    handleUsernameChange(e: ChangeEvent<FormControlProps>): void {
        this.setState({
             ...this.state,
            username: e.target.value as string});
    }

    /**
     * On password input field change.
     * @param e The change event.
     */
    handlePasswordChange(e: ChangeEvent<FormControlProps>): void {
        this.setState({
            ...this.state,
           password: e.target.value as string});        
    }

    /**
     * On render.
     * @returns A react node.
     */
    render(): ReactNode {
        return (
            <div className="page-sign d-block py-0">
                <Row className="g-0">
                    <Col md="7" lg="5" xl="4" className="col-wrapper">
                        <Card className="card-sign shadow">
                            <Card.Header>
                                <Card.Title>
                                    <img src={ccLogo} style={{width: "100%"}}></img>
                                    <img src={fdbLogo} style={{width: "44%", marginTop: "8px", marginLeft: "4px"}}></img>
                                </Card.Title>
                                <Card.Text style={{marginLeft: "5px"}}>Welcome back! Please sign in to continue.</Card.Text>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={e => e.preventDefault()}>
                                    <div className="mb-4">
                                        <Form.Label className="d-flex justify-content-between">
                                            <span>Username</span>
                                            <span className="muted text-danger small">{this.state.usernameErrorMessage}</span>
                                        </Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Enter your email address"
                                            ref={this.usernameInputRef}
                                            value={this.state.username}
                                            isInvalid={this.state.usernameIsInValid}
                                            onChange={(e) => {this.handleUsernameChange(e as any)}} />
                                    </div>
                                    <div className="mb-4">
                                        <Form.Label className="d-flex justify-content-between">
                                            <span>Password</span>
                                            <span className="muted text-danger small">{this.state.passwordErrorMessage}</span>
                                        </Form.Label>
                                        <Form.Control 
                                            type="password"
                                            placeholder="Enter your password"
                                            ref={this.passwordInputRef}
                                            value={this.state.password}
                                            isInvalid={this.state.passwordIsInValid}
                                            onChange={(e) => {this.handlePasswordChange(e as any)}} />
                                    </div>
                                    <Button type="submit" className="btn-sign" onClick={() => this.signIn()}>Sign In</Button>
                                </Form>
                            </Card.Body>
                            <Card.Footer>
                                &nbsp;
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col className="d-lg-block">
                        <img src={bg1} className="auth-img" alt="" />
                    </Col>
                </Row>
            </div>
        )
    }
}

// Export the sign in page.
export default withRouter(SignIn);
