import React, { Component, MouseEventHandler, ReactNode } from "react";
import { CAFMAssetDetails } from "../utils/assetMappingService";
import SmallCard from "./SmallCard";

/**
 * The props for the CAFMAssetSmall component.
 */
export type CAFMAssetSmallProps = {
    /** The CAFM asset details. */
    asset: CAFMAssetDetails
    /** Show warning colours on this component.  */
    warning: boolean,
    /** Can we click this asset card? */
    isClickable?: boolean,
    /** On click handler. */
    onClick?: MouseEventHandler<HTMLDivElement>
}

/**
 * A small component to display select information from the CAFMAssetDetails type.
 */
class CAFMAssetSmall extends Component<CAFMAssetSmallProps> {
    /** On render. */
    render(): ReactNode {
        const {warning, isClickable, onClick} = this.props;
        const {assetDescription, manufacturer, modelType, locationNumber} = this.props.asset;
        return (
            <SmallCard 
                borderCol={warning != null && warning ? "warning" : undefined}
                textCol={warning != null && warning ? "warning" : undefined}
                isClickable={isClickable}
                onClick={isClickable ? onClick : undefined}>
                <span className="d-flex justify-content-between">
                    <span className="me-5 fw-bold">{assetDescription}</span>
                    <span className="font-monospace">{locationNumber}</span>
                    
                </span>
                <pre className="mb-0">
                    {manufacturer + ((manufacturer != null && manufacturer != "" && modelType != null && modelType != "") ? " - " : "") + modelType}
                </pre>
            </SmallCard>
        );
    }

}

export default CAFMAssetSmall;