import showdown from "showdown";
import moment from "moment-timezone";
import _ from "lodash";
import { Component, ReactNode } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { AuditLogEntry, fullLog } from "../utils/auditLogService";

/**
 * State for the asset mapping page component.
 */
type AssetMappingPageState = {
    // List of mappings from the server.
    entries: Array<AuditLogEntry>
}

/**
 * Audit Log page.
 */
class AuditLogPage extends Component<RouteComponentProps, AssetMappingPageState> {

    /** A markdown compiler. */
    markdownCompiler = (() => {
        const comp = new showdown.Converter();
        comp.setOption("openLinksInNewWindow", true);
        return comp;
    })();

    

    state = { entries: [] }

    /** On component mount. */
    componentDidMount(): void {
        fullLog().then(res => {
            this.setState(state => { return {
                ...state,
                entries: res.entries
            }});
        });
    }

    getTypeHtml = (tt: Number) => {
        switch (tt) {
            case 1:
                return <span style={{fontSize:"20px"}}><i className="ri-error-warning-line text-danger" /></span>
            case 2:
                return <span style={{fontSize:"20px"}}><i className="ri-error-warning-line text-warning" /></span>
            case 3:
                return <span style={{fontSize:"20px", visibility: "hidden"}}><i className="ri-information-line text-secondary" /></span>
        }
    }

    /**
     * On render.
     * @returns A React node.
     */
    render(): ReactNode {
        const entries = this.state.entries.map(({message, ts, type: tt}) => {
            return (
                <div className="card card-light card-bless my-2" key={ts}>
                    <div className="card-header" style={{fontWeight: "bold"}}>{moment(ts).format("MMM D yyyy, h:mm:ss a")}</div>
                    <div className="card-body" style={{flexDirection: "row", display: "flex", alignItems: "center", paddingTop: "0", paddingBottom: "0"}}>
                        <div style={{paddingRight: "12px"}}>{this.getTypeHtml(tt)}</div>
                        <div style={{paddingTop: "12px"}} dangerouslySetInnerHTML={{__html: this.markdownCompiler.makeHtml(message)}}></div>
                    </div>
                </div>
            )
        })
        return (
            <div className="main px-4 py-3 px-lg-5 py-lg-4">
                <h2><i className="ri-information-line" style={{color: "#506fd9", paddingRight: "15px"}} />Info Log</h2>
                <hr />
                {entries}
            </div>
        );
    }
}

export default withRouter(AuditLogPage);