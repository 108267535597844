import { webGet, webPost } from "./utils";

/** The CAFM asset details. */
export type CAFMAssetDetails = {
    /** The asset ID, this is the `asset` field in the spreadsheet. */
    id: number,
    /** The estimated asset lifespan. */
    lifespan: number,
    /** The building the asset is located in. */
    building: String,
    /** The asset code. */
    assetCode: String,
    /** The asset description. */
    assetDescription: String,
    /** The asset system. Not the Copper Tree system, unfortunately. */
    assetSystem: String,
    /** The asset tag. */
    assetTag: String,
    /** The asset type. */
    assetType: String | null,
    /** The asset location number. */
    locationNumber: String | null,
    /** The asset location. */
    location: String | null,
    /** The quantity of this asset. */
    assetQuantity: Number,
    /** The asset status. */
    assetStatus: String,
    /** The asset condition. */
    condition: Number,
    /** The asset manufacturer. */
    manufacturer: String | null,
    /** The asset type ID. */
    assetTypeId: String | null,
    /** The asset serial number. */
    serialNumber: String | null,
    /** The asset model type. */
    modelType: String | null,
    /** The asset group. */
    assetGroup: String | null,
    /** The calculated risk of the asset. */
    calculatedRisk: Number,
    /** The locational status. */
    locationalStatus: Number,
    /** The date the asset was purchased. */
    datePurchased: String | null,
    /** The asset barcode. */
    assetBarcode: String | null,
    /** An external reference attached to the asset. */
    externalRef: String | null
}

/**
 * An asset mapping.
 */
export type AssetMapping = {
    /** The copper tree system ID. */
    copperTreeSystemId: string,
    /** The copper tree system name. */
    copperTreeSystemName: string,
    /** The copper tree system tags. */
    copperTreeSystemTags: Array<String>,
    /** Is the copper tree system ignored? */
    copperTreeSystemIgnored: boolean,
    /** The BMS asset ID. */
    bmsAssetId: number | undefined,
    /** The BMS asset details. */
    bmsAssetDetails: CAFMAssetDetails | undefined,
}

/** Copper Tree system details. */
export type CopperTreeSystemDetails = {
    /** The ID of the Copper Tree system. */
    id: String,
    /** The name of the Copper Tree system. */
    name: String,
    /** The Copper Tree system tags. */
    tags: Array<String>
}

/**
 * The all asset mappings response object.
 */
export type APIAllAssetMappingResponse = {
    allAssetMappings: Array<AssetMapping>
}

/**
 * Fetch all asset mappings from the backend.
 * @param cancelAbortController An optional abort controller passed in by the
 * user to cancel the request.
 * @returns The response from the asset mapping endpoint.
 */
export function allAssetMappings(
    cancelAbortController: AbortController | undefined = undefined
    ): Promise<APIAllAssetMappingResponse> {
    return webGet("/config/asset-mapping", cancelAbortController);
}

/**
 * The update asset mappings response object.
 */
export type APIUpdateAssetMappingResponse = { 
    /** Did anything update? */
    ok: Boolean
}

/**
 * An asset mapping update.
 */
export type AssetMappingUpdate = {
    /** The copper tree system ID. */
    copperTreeSystemId: string,
    /** The BMS asset ID. */
    bmsAssetId?: number,
    /** Is this Copper Tree system ignored? */
    copperTreeSystemIgnored: boolean
}

/**
 * Calls the backend with the asset mapping updates.
 * @param updates An array of asset mapping updates.
 * @param cancelAbortController An optional abort controller passed in by the
 *  user to cancel the request.
 * @returns The update asset mapping response.
 */
export function updateAssetMappings(
    updates: Array<AssetMappingUpdate>,
    cancelAbortController: AbortController | undefined = undefined
    ): Promise<APIUpdateAssetMappingResponse> {
    return webPost(
        "/config/asset-mapping",
        {updates: updates},
        cancelAbortController);
}

/** An CAFM asset search response. */
export type APISearchCAFMAssetResponse = {
    /** The matching assets. */
    matchingAssets: Array<CAFMAssetDetails>
}

/**
 * Performs a search for CAFM assets on the backend.
 * @param query A query string.
 * @param cancelAbortController An optional abort controller passed in by the
 *  user to cancel the request.
 * @returns The matching CAFM assets.
 */
export function searchCafmAssets(
    query: String,
    cancelAbortController: AbortController | undefined = undefined
    ): Promise<APISearchCAFMAssetResponse> {
    return webGet("/config/asset-search", {query}, cancelAbortController);
}