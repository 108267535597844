import React, { Component, ReactNode } from "react";
import { COLOUR_PALETTE, hashCode } from "../utils/utils";

/**
 * The props for the coloured badge component.
 */
export type ColouredTagProps = {
    /** The tag string. */
    tag?: String,
}

/**
 * A component to make a coloured badge from a string. The colour is 
 * automatically assigned.
 */
class ColouredBadge extends Component<ColouredTagProps> {
    /** On render. */
    render(): ReactNode {
        if (this.props.tag == null) {
            return <></>;
        }

        // Get the background colour.
        const bgCol = COLOUR_PALETTE[Math.abs(hashCode(this.props.tag)) % COLOUR_PALETTE.length];

        return (
            <span className={"shadow-sm badge me-2"} style={{backgroundColor: bgCol}}>
                {this.props.tag}
            </span>
        );
    }

}

export default ColouredBadge;